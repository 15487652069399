import React, { useState, memo } from "react";
import { App, Button, message, Steps, Radio, Input, Select, ConfigProvider } from 'antd';
import { createRoot } from 'react-dom/client';
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from 'yup';
import ReCAPTCHA from "react-google-recaptcha";
import paises from './paises.json';
import axios from "axios";
import PhoneInput from "antd-phone-input";

export default function Main() {
    const [current, setCurrent] = useState(0);
    const [ciudades, setCiudades] = useState([]);
    const { modal } = App.useApp();

    const [values, setValues] = useState({
        name: "",
        category: "comida",
        branch_no: "1",
        pais: 'Bolivia',
        ciudad: 'Cochabamba',
        logo: "",
        contact: "",
        email: "",
        password: "",
        password_repeat: "",
        captcha: "",
        ciudades: []
    });

    const FormError = memo(({ children }) => {
        return (
            <div className="text-danger">
                <small>
                    <i className="fa-solid fa-circle-exclamation me-1"></i>
                    {children}
                </small>
            </div>
        );
    })

    const Title = () => {
        return (
            <div>
                <div className="w-50 mx-auto mb-3">
                    <img className="w-100" src="/assets_one/img/logo_quick_one.png" alt=""
                        style={{ height: '4em', objectFit: 'contain' }}
                    />
                </div>
                <div className="mb-3 text-center" style={{ fontSize: '1.2em' }}>
                    <span className="h5">
                        Todo el control de tu negocio aquí <br />
                        No necesitas tarjeta de crédito.
                    </span>
                    <hr />
                </div>
            </div>
        );
    }

    const Step1 = () => {
        const validationStep1 = Yup.object({
            name: Yup.string()
                .required('El campo es requerido.'),
            category: Yup.string()
                .required('El campo es requerido.'),
        });

        const handleSubmitStep1 = (values) => {
            setValues(prev => ({ ...prev, ...values }));
            next();
        }

        return (
            <div className="p-3" style={{ fontSize: '0.9em' }}>
                <Formik
                    initialValues={values}
                    onSubmit={handleSubmitStep1}
                    validationSchema={validationStep1}
                    enableReinitialize={true}
                >
                    {
                        (formik) => (
                            <Form>
                                <div style={{ minHeight: '90vh' }}>
                                    <Title />
                                    <div className="mb-3">
                                        <label className="fw-bold mb-2" htmlFor="">¿Cuál es el nombre de tu negocio?</label>
                                        <span className="text-danger ps-1">(*)</span>
                                        <input type="text" className="form-control form-control-sm"
                                            placeholder="Nombre de tu negocio."
                                            value={formik.values.name}
                                            onChange={(e) => formik.setFieldValue('name', e.target.value)}
                                        />
                                        <ErrorMessage name="name" component={FormError} />
                                    </div>

                                    <div className="mb-2">
                                        <label htmlFor="" className="fw-bold mb-2">¿En qué rubro está tu negocio?</label>
                                        <span className="text-danger ps-1">(*)</span>
                                        <div className="mb-3 mt-2">
                                            <Radio.Group onChange={(e) => formik.setFieldValue('category', e.target.value)}
                                                value={formik.values.category} >
                                                <div className="row mb-2 gx-3 gy-3">
                                                    <div className="col-md-6">
                                                        <div className={`rubro rounded-3 border border-2 px-3 ${(formik.values.category === "comida") ? 'border-dark-subtle bg-body-secondary' : 'border-opacity-75'}`}>
                                                            <Radio value={'comida'}>
                                                                <div className="row align-items-center">
                                                                    <div className="col-4">
                                                                        <img src="/assets_one/img/multistep/comida.png" alt=""
                                                                            className="w-100"
                                                                        />
                                                                    </div>
                                                                    <div className="col">
                                                                        <div className="fw-bold ps-2 text-center">
                                                                            COMIDA
                                                                        </div>
                                                                        <div className="ps-2 text-center">
                                                                            Restaurantes, heladerías, cafeterías, bares.
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Radio>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className={`rubro rounded-3 border border-2 px-3 ${(formik.values.category === "productos") ? 'border-dark-subtle bg-body-secondary' : 'border-opacity-75'}`}>
                                                            <Radio value={'productos'}>
                                                                <div className="row align-items-center">
                                                                    <div className="col-4">
                                                                        <img src="/assets_one/img/multistep/productos.png" alt=""
                                                                            className="w-100"
                                                                        />
                                                                    </div>
                                                                    <div className="col">
                                                                        <div className="fw-bold ps-2 pt-2 text-center">
                                                                            PRODUCTOS
                                                                        </div>
                                                                        <div className="ps-2 text-center">
                                                                            Venta ropa y accesorios, venta electrónicos, venta alimentos envasados.
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Radio>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className={`rubro rounded-3 border border-2 px-3 ${(formik.values.category === "servicios") ? 'border-dark-subtle bg-body-secondary' : 'border-opacity-75'}`}>
                                                            <Radio value={'servicios'}>
                                                                <div className="row align-items-center">
                                                                    <div className="col-4">
                                                                        <img src="/assets_one/img/multistep/servicios.png" alt=""
                                                                            className="w-100 py-2"
                                                                        />
                                                                    </div>
                                                                    <div className="col">
                                                                        <div className="fw-bold ps-2 pt-2 text-center">
                                                                            SERVICIOS
                                                                        </div>
                                                                        <div className="ps-2 text-center">
                                                                            Peluquerías, Spas, Asesores Inmobiliarios, Asesores Legales, Servicios de Limpieza, etc.
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Radio>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className={`rubro rounded-3 border border-2 px-3 ${(formik.values.category === "manufactura") ? 'border-dark-subtle bg-body-secondary' : 'border-opacity-75'}`}>
                                                            <Radio value={'manufactura'}>
                                                                <div className="row align-items-center">
                                                                    <div className="col-4">
                                                                        <img src="/assets_one/img/multistep/servicios.png" alt=""
                                                                            className="w-100 py-2"
                                                                        />
                                                                    </div>
                                                                    <div className="col">
                                                                        <div className="fw-bold ps-2 pt-2 text-center">
                                                                            MANUFACTURA DE PRODUCTOS
                                                                        </div>
                                                                        <div className="ps-2 text-center">
                                                                            Fabricación de alimentos, fabricación de artículos.
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Radio>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Radio.Group>
                                        </div>
                                    </div>
                                    <div className="row my-2 text-end">
                                        <div className="row">
                                            <div className="col-md">
                                            </div>
                                            <div className="col-md">
                                                <Button type="success"
                                                    style={{ margin: '0 8px', backgroundColor: '#0bb783' }}
                                                    htmlType="submit"
                                                    className="w-100 btn btn-sm text-white rounded-pill"
                                                >
                                                    Siguiente
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        )
                    }
                </Formik>
            </div>
        );
    }

    const Step2 = () => {
        const validationStep2 = Yup.object({
            branch_no: Yup.number()
                .max(50, "Max: 50")
                .min(0, "Min: 0")
                .required('El campo es requerido.'),
        });

        const handleSubmitStep2 = (values) => {
            setValues(prev => ({ ...prev, ...values }));
            next();
        }

        return (
            <div className="p-3" style={{ fontSize: '0.9em' }}>
                <Formik
                    initialValues={values}
                    onSubmit={handleSubmitStep2}
                    validationSchema={validationStep2}
                    enableReinitialize={true}
                >
                    {
                        (formik) => (
                            <Form>
                                <div className="d-flex flex-column" style={{ minHeight: '90vh' }}>
                                    <Title />
                                    <div className="mb-2 flex-grow-1">
                                        <label htmlFor="" className="fw-bold mb-2">¿Cuántas sucursales tiene tu negocio?</label>
                                        <input type="number" name="branch_no" id="branch_no"
                                            className="form-control"
                                            value={formik.values.branch_no}
                                            onChange={(e) => formik.setFieldValue('branch_no', e.target.value)}
                                        />
                                        <ErrorMessage name="branch_no" component={FormError} />
                                    </div>
                                    <div className="row my-2 text-end">
                                        <div className="row">
                                            <div className="col-md">
                                                <Button style={{ margin: '0 8px' }}
                                                    type="default"
                                                    htmlType="button"
                                                    className="w-100 rounded-pill"
                                                    onClick={prev}
                                                >
                                                    Anterior
                                                </Button>
                                            </div>
                                            <div className="col-md">
                                                <Button type="success"
                                                    style={{ margin: '0 8px', backgroundColor: '#0bb783' }}
                                                    htmlType="submit"
                                                    className="w-100 btn btn-sm text-white rounded-pill"
                                                >
                                                    Siguiente
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        )
                    }
                </Formik>
            </div>
        );
    }

    const Step3 = () => {
        const validationStep3 = Yup.object({
            contact: Yup.string()
                .max(200, "El texto es muy largo.")
                .notRequired(),
            pais: Yup.string()
                .required('El campo es obligatorio.'),
            ciudad: Yup.string()
                .required('El campo es obligatorio.')
        });

        const handleSubmitStep3 = (values) => {
            setValues(prev => ({ ...prev, ...values }));
            next();
        }

        return (
            <div className="p-3" style={{ fontSize: '0.9em' }}>
                <Formik
                    initialValues={values}
                    onSubmit={handleSubmitStep3}
                    validationSchema={validationStep3}
                    enableReinitialize={true}
                >
                    {
                        (formik) => (
                            <Form>
                                <div className="d-flex flex-column" style={{ minHeight: '90vh' }}>
                                    <Title />
                                    <div className="mb-2 flex-grow-1">
                                        <div className="mb-4">
                                            <label htmlFor="logo" className="fw-bold">
                                                Sube el logo de tu Negocio (Opcional)</label>
                                            <input type="file" name="logo" id="logo"
                                                className="form-control"
                                                //value={formik.values.logo}
                                                onChange={(e) => formik.setFieldValue('logo', e.target.files[0])}
                                            />
                                            <ErrorMessage name="branch_no" component={FormError} />
                                        </div>
                                        <div className="mb-4">
                                            <label htmlFor="contact" className="fw-bold">
                                                ¿Cuál es el número celular de tu negocio (Opcional)?
                                            </label>
                                            <PhoneInput
                                                enableSearch
                                                value={formik.values.contact}
                                                onChange={
                                                    (val) => formik.setFieldValue(
                                                        'contact',
                                                        `${val.countryCode}${val.areaCode}${val.phoneNumber}`
                                                    )
                                                }
                                                placeholder="Ingrese valor"
                                            />

                                            <ErrorMessage name="contact" component={FormError} />
                                        </div>
                                    </div>

                                    <div className="row my-2 text-end">
                                        <div className="row">
                                            <div className="col-md">
                                                <Button style={{ margin: '0 8px' }}
                                                    type="default"
                                                    htmlType="button"
                                                    className="w-100 rounded-pill"
                                                    onClick={prev}
                                                >
                                                    Anterior
                                                </Button>
                                            </div>
                                            <div className="col-md">
                                                <Button type="success"
                                                    style={{ margin: '0 8px', backgroundColor: '#0bb783' }}
                                                    htmlType="submit"
                                                    className="w-100 btn btn-sm text-white rounded-pill"
                                                >
                                                    Siguiente
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        )
                    }
                </Formik>
            </div>
        );
    }

    const Step4 = () => {
        const validationStep4 = Yup.object({
            email: Yup.string()
                .email('Email no válido')
                .required('El campo es requerido.'),
            password: Yup.string()
                .min(4, 'Ingrese al menos 4 caracteres.')
                .required('El campo es requerido.'),
            password_repeat: Yup.string()
                .required('El campo es requerido.')
                .oneOf([Yup.ref('password'), null], 'Las contraseñas no coinciden.'),
            captcha: Yup.string()
                .required('Compruebe que es humano.')
        });

        const handleSubmitStep4 = async (values, formik) => {
            let formData = new FormData();
            formData.append('nombre', values.name);
            formData.append('email', values.email);
            formData.append('password', values.password);
            formData.append('password_confirm', values.password_repeat);
            formData.append('pais', values.pais);
            formData.append('ciudad', values.ciudad);
            formData.append('celular', values.contact);
            formData.append('logo', values.logo);
            formData.append('g-recaptcha-response', values.captcha);

            let res = await axios.post('/submit-registro', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            if (res.data.code === 400) {
                formik.setFieldError('email', 'El email ya se encuentra registrado.');
            }

            if (res.data.code === 200) {
                modal.info({
                    content: (
                        <div>
                            ¡Cuenta registrada! <br />
                            Ahora ingresa al sistema con los credenciales con los que creaste tu cuenta... <br />
                            Click en aceptar para ir a inicio de sesión...
                        </div>
                    )
                }).then(() => {
                    window.location.href = "https://myquickone.com/login";
                });
            }

        }

        return (
            <div className="p-3" style={{ fontSize: '0.9em' }}>
                <Formik
                    initialValues={values}
                    onSubmit={handleSubmitStep4}
                    validationSchema={validationStep4}
                    enableReinitialize={true}
                >
                    {
                        (formik) => (
                            <Form>
                                <div className="d-flex flex-column" style={{ minHeight: '90vh' }}>
                                    <Title />
                                    <div className="mb-2 flex-grow-1">
                                        <div className="mb-2">
                                            <label htmlFor="branch_no">Correo electrónico:</label>
                                            <span className="text-danger ps-1">(*)</span>
                                            <input type="email" name="email" id="email"
                                                className="form-control form-control-sm"
                                                value={formik.values.email}
                                                onChange={(e) => formik.setFieldValue('email', e.target.value)}
                                            />
                                            <ErrorMessage name="email" component={FormError} />
                                        </div>
                                        <div className="row mb-3">
                                            <div className="col-md">
                                                <label htmlFor="password">Contraseña:</label>
                                                <span className="text-danger ps-1">(*)</span>
                                                <Input.Password
                                                    value={formik.values.password}
                                                    onChange={(e) => formik.setFieldValue('password', e.target.value)}
                                                />
                                                <ErrorMessage name="password" component={FormError} />
                                            </div>
                                            <div className="col-md">
                                                <label htmlFor="password_repeat">Repita la Contraseña:</label>
                                                <span className="text-danger ps-1">(*)</span>
                                                <Input.Password
                                                    value={formik.values.password_repeat}
                                                    onChange={(e) => formik.setFieldValue('password_repeat', e.target.value)}
                                                />
                                                <ErrorMessage name="password_repeat" component={FormError} />
                                            </div>
                                        </div>
                                        <div className="mb-3 d-flex justify-content-center">
                                            <ReCAPTCHA
                                                sitekey="6LeuhXIlAAAAAFsz6LTbhcqgKC-QwyGGI47Hy99y"
                                                value={formik.values.captcha}
                                                onChange={(value) => formik.setFieldValue('captcha', value)}
                                            />
                                        </div>
                                        <ErrorMessage name="captcha" component={FormError} />
                                    </div>
                                    <div className="row my-2 text-end">
                                        <div className="row">
                                        <div className="col-md">
                                                <Button style={{ margin: '0 8px' }}
                                                    type="default"
                                                    htmlType="button"
                                                    className="w-100 rounded-pill"
                                                    onClick={prev}
                                                >
                                                    Anterior
                                                </Button>
                                            </div>
                                            <div className="col-md">
                                                <Button type="success"
                                                    style={{ margin: '0 8px', backgroundColor: '#0bb783' }}
                                                    htmlType="submit"
                                                    className="w-100 btn btn-sm text-white rounded-pill"
                                                >
                                                    Siguiente
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        )
                    }
                </Formik>
            </div>
        );
    }

    const steps = [
        {
            title: 'Nombre y categoría',
            content: <Step1 />,
        },
        {
            title: 'Número de sucursales',
            content: <Step2 />,
        },
        {
            title: 'Datos de la empresa',
            content: <Step3 />,
        },
        {
            title: 'Datos de inicio',
            content: <Step4 />,
        },
    ];

    const next = () => {
        setCurrent(current + 1);
    };

    const prev = () => {
        setCurrent(current - 1);
    };

    const items = steps.map((item) => ({
        key: item.title,
        title: item.title,
    }));

    const contentStyle = {
        backgroundColor: 'white',
        borderRadius: '0.5em',
        // marginTop: 16,
        margin: '0 0.5em'
    };

    return (
        <>
            <div className="row">
                <div className="col-md-auto py-3">
                    <Steps
                        size="small"
                        direction="vertical"
                        current={current}
                        items={items}
                    />
                </div>
                <div className="col-md">
                    <div style={contentStyle}>
                        {steps[current].content}
                    </div>
                </div>
            </div>
        </>
    )
}

const main = document.getElementById('multistep-main');

if (main) {
    if (!main.root) { main.root = createRoot(main); }
    main.root.render(
        <React.StrictMode>
            <ConfigProvider theme={{
                token: {
                    fontFamily: 'Poppins'
                }
            }}>
                <App>
                    <Main />
                </App>
            </ConfigProvider>
        </React.StrictMode>
    );
}
